import {
  OrganizationProfile,
  useOrganization,
  useOrganizationList,
} from "@clerk/nextjs";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@winclap-platform/ui/components/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@winclap-platform/ui/components/dropdown-menu";
import { Settings2 } from "lucide-react";
import Image from "next/image";
import { useParams } from "next/navigation";
import { useRouter } from "next/router";

export default function CompanySwitcher() {
  const { setActive, userMemberships, isLoaded } = useOrganizationList({
    userMemberships: {
      infinite: true,
      pageSize: 50,
    },
  });
  const { organization: activeOrg } = useOrganization();
  const router = useRouter();
  const params = useParams();

  if (!isLoaded || !activeOrg) {
    return null;
  }

  return (
    <Dialog>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger asChild>
          <Image
            unoptimized
            src={activeOrg.imageUrl}
            width={40}
            height={40}
            alt={activeOrg.name}
            className="h-[40px] cursor-pointer rounded border border-white object-cover"
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent
          side="right"
          className="absolute left-6 top-[-37px] w-64"
        >
          <div className="flex items-center gap-4 px-4 py-3">
            <Image
              unoptimized
              src={activeOrg.imageUrl}
              width={40}
              height={40}
              alt={activeOrg.name}
              className="h-[40px] rounded border border-gray-500 object-cover"
            />
            <div className="flex flex-col">
              <p className="text-sm font-semibold">{activeOrg.name}</p>
              <DialogTrigger asChild>
                <DropdownMenuItem className="hover:bg-primary-20 flex cursor-pointer items-center gap-1 rounded p-1">
                  <Settings2 className="size-4 text-gray-500" />
                  <span className="pr-4 text-xs text-gray-500">
                    Company settings
                  </span>
                </DropdownMenuItem>
              </DialogTrigger>
            </div>
          </div>
          <DropdownMenuSeparator />
          <div className="flex max-h-[500px] flex-col overflow-auto p-2">
            {userMemberships.data.map(({ organization }) => {
              const isActive = organization.id === activeOrg.id;
              return (
                !isActive && (
                  <DropdownMenuItem
                    key={organization.id}
                    onClick={async () => {
                      await setActive({
                        organization: organization.id,
                        beforeEmit: async () => {
                          const isCompanySlugMatch =
                            router.query.companySlug === activeOrg.slug;
                          const isEditing = router.pathname.includes("edit");

                          if (isCompanySlugMatch && !params?.reportId) {
                            await router.replace(
                              {
                                pathname: router.pathname,
                                query: {
                                  ...params, // Keep only path parameters
                                  companySlug: organization.slug,
                                },
                              },
                              undefined,
                              { shallow: true },
                            );
                          } else if (isEditing || params?.reportId) {
                            await router.push("/reporting");
                          } else if (!isCompanySlugMatch) {
                            await router.replace(
                              {
                                pathname: router.pathname,
                                query: {
                                  ...params, // Keep only path parameters
                                },
                              },
                              undefined,
                              { shallow: true },
                            );
                          }
                        },
                      });
                    }}
                    className="hover:bg-primary-20 w-full cursor-pointer rounded p-2"
                  >
                    <Image
                      unoptimized
                      src={organization.imageUrl}
                      width={40}
                      height={40}
                      alt={organization.name}
                      className={`h-[40px] rounded border border-gray-500 object-cover`}
                    />
                    <div className="ml-4 font-semibold">{organization.name}</div>
                  </DropdownMenuItem>
                )
              );
            })}
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
      <DialogContent
        closable={false}
        className="top-[45%] flex h-4/5 w-screen max-w-max border-none bg-transparent shadow-none"
      >
        <OrganizationProfile routing="hash" />
      </DialogContent>
    </Dialog>
  );
}
