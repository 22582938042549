import { SignIn } from "@clerk/nextjs";
import Image from "next/image";

export function LogIn() {
  return (
    <div className="flex h-screen w-full justify-between">
      <div className="flex w-full flex-col justify-center lg:w-1/2">
        <h1 className="font-judson mb-12 text-center text-5xl">
          Winclap <span className="text-primary-200">Catalyst</span>
        </h1>
        <div className="flex w-full justify-center">
          <SignIn routing="hash" />
        </div>
        <Image
          src={`${process.env.NEXT_PUBLIC_WINCLAP_CDN}/images/branding/logo/winclap.svg`}
          width={300}
          height={75}
          alt="winclap logo"
          className="mx-auto mt-12"
        />
      </div>
      <div className="bg-primary-100 hidden h-full w-1/2 justify-end overflow-hidden bg-[url('/images/illustration.png')] bg-contain bg-center bg-no-repeat lg:flex" />
    </div>
  );
}
