import { UserProfile, useClerk, useUser } from "@clerk/nextjs";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@winclap-platform/ui/components/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@winclap-platform/ui/components/dropdown-menu";
import { LogOut, Settings2 } from "lucide-react";
import Image from "next/image";
import { useRouter } from "next/router";

export default function UserButton() {
  const { user, isLoaded } = useUser();
  const { signOut } = useClerk();
  const router = useRouter();

  if (!isLoaded || !user) {
    return null;
  }

  return (
    <Dialog>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger asChild>
          <Image
            unoptimized
            src={user.imageUrl}
            width={40}
            height={40}
            alt="user profile picture"
            className="size-10 cursor-pointer rounded border border-white object-cover"
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent side="right" className="mb-6 ml-6 w-64">
          <div className="my-3 flex items-center gap-3 px-3">
            <Image
              unoptimized
              src={user.imageUrl}
              width={48}
              height={48}
              alt="user profile picture"
              className={`size-10 rounded border border-black object-cover`}
            />
            <div className="flex flex-col">
              <p className="font-semibold">{user.fullName}</p>
              <p className="mr-1 text-xs text-gray-500">
                {user.primaryEmailAddress?.emailAddress}
              </p>
            </div>
          </div>
          <DropdownMenuSeparator />
          <DialogTrigger asChild>
            <DropdownMenuItem
              onClick={() => null}
              className="hover:bg-primary-20 m-2 cursor-pointer gap-4 rounded p-2"
            >
              <Settings2 className="" />
              <p className="font-semibold">Profile settings</p>
            </DropdownMenuItem>
          </DialogTrigger>
          <DropdownMenuItem
            onClick={() => signOut(() => router.push("/"))}
            className="hover:bg-primary-20 m-2 cursor-pointer gap-4 rounded p-2"
          >
            <LogOut className="" />
            <p className="font-semibold">Log out</p>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <DialogContent
        closable={false}
        className="top-[45%] flex h-4/5 w-screen max-w-max border-none bg-transparent shadow-none"
      >
        <UserProfile routing="hash" />
      </DialogContent>
    </Dialog>
  );
}
