import { SignOutButton } from "@clerk/nextjs";
import { Button } from "@winclap-platform/ui/components/button";
import { AlertCircle } from "lucide-react";

export function OrganizationNotFound() {
  return (
    <main className="flex min-h-screen flex-col items-center justify-center bg-gray-100 p-4 dark:bg-gray-900">
      <div className="w-full max-w-md space-y-8">
        <div className="text-center">
          <AlertCircle className="mx-auto size-12 text-gray-900 dark:text-gray-100" />
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900 dark:text-gray-100">
            No Company Found
          </h2>
          <p className="mb-4 mt-2 text-sm text-gray-600 dark:text-gray-400">
            It seems like you are not currently a member of any organization within
            the app. In order to access the app, your company&apos;s administrator
            will need to invite you to join.
          </p>
          <SignOutButton>
            <Button variant="destructive">Sign Out</Button>
          </SignOutButton>
        </div>
      </div>
    </main>
  );
}
