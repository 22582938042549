import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "@winclap-platform/ui/components/toaster";
import { TooltipProvider } from "@winclap-platform/ui/components/tooltip";
import { AppType } from "next/dist/shared/lib/utils";
import Head from "next/head";
import Layout from "~/components/layout";
import { AuthProvider } from "~/context/auth/auth-provider";
import { PosthogProvider } from "~/context/posthog-provider";
import "~/styles/globals.css";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false },
  },
});

const MyApp: AppType = ({ Component, pageProps }) => {
  return (
    <>
      <Head>
        <title>Winclap Catalyst</title>
      </Head>
      <AuthProvider {...pageProps}>
        <PosthogProvider>
          <QueryClientProvider client={queryClient}>
            <TooltipProvider>
              <Layout>
                <Toaster />
                <Component {...pageProps} />
              </Layout>
            </TooltipProvider>
          </QueryClientProvider>
        </PosthogProvider>
      </AuthProvider>
    </>
  );
};

export default MyApp;
