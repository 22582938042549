import { Sidebar } from "./sidebar";

export default function Layout({ children }: React.PropsWithChildren) {
  return (
    <>
      <div className="flex min-h-screen bg-gray-50 pl-[88px]">
        <Sidebar />
        {children}
      </div>
    </>
  );
}
