"use client";

export interface LogoProps extends React.ComponentProps<"svg"> {
  animate?: boolean;
}

export const Logo = ({
  width = 96,
  color = "black",
  animate,
  ...props
}: LogoProps) => {
  return (
    <svg
      id="winclap-loader"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 514.54 514.54"
      color={color}
      fill="currentColor"
      width={width}
      {...props}
    >
      <path
        className={animate ? "animate-winclap-triangle-1" : ""}
        d="M248.81,310.17,157.45,419.34,66,310.17H248.81m18.86-8.81H47.13l108.06,129h4.52l108-129Z"
        vectorEffect="non-scaling-stroke"
      />
      <path
        className={animate ? "animate-winclap-triangle-2" : ""}
        d="M343.83,210.54,257.09,413.22,170.73,210.54h173.1m13.35-8.81H157.4l97.41,228.63h4.52l97.85-228.63Z"
        vectorEffect="non-scaling-stroke"
      />
      <path
        className={animate ? "animate-winclap-triangle-3" : ""}
        d="M445.37,110.92,357.18,407.08,269,110.92H445.37m11.81-8.81h-200l97.74,328.25h4.52l97.74-328.25Z"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};
