import { OrganizationList, useOrganization, useUser } from "@clerk/nextjs";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { OrganizationNotFound } from "~/components/auth/org-not-found";

export const OrganizationGuard = ({ children }: { children: React.ReactNode }) => {
  const { isLoaded, user, isSignedIn } = useUser();
  const { isLoaded: isOrgLoaded, organization } = useOrganization();
  const posthog = usePostHog();

  useEffect(() => {
    if (isSignedIn && organization) {
      posthog.identify(user.primaryEmailAddress?.emailAddress);
    }
  }, [isSignedIn, organization, posthog, user]);

  if (!isLoaded || !isOrgLoaded) {
    return null;
  }
  if (!user?.organizationMemberships.length) {
    return <OrganizationNotFound />;
  }
  if (!organization && user.organizationMemberships.length > 0) {
    return (
      <main className="flex h-screen w-full items-center justify-center">
        <OrganizationList hidePersonal />
      </main>
    );
  } else {
    return children;
  }
};
