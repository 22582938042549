import { loaderKeyframes } from "@winclap-platform/ui/animations/loader";
import { Config } from "tailwindcss";
import colors from "tailwindcss/colors";
import tailwindcssAnimate from "tailwindcss-animate";

const winclapColors = {
  green: {
    DEFAULT: "#1CC9B9",
    "10": "#E8FAF8",
    "20": "#D2F4F1",
    "40": "#A4E9E3",
    "60": "#77DFD5",
    "80": "#49D4C7",
    "100": "#1CC9B9",
    "200": "#00A192",
  },
  blue: {
    DEFAULT: "#87DBFF",
    "20": "#E7F8FF",
    "40": "#CFF1FF",
    "60": "#B7E9FF",
    "80": "#9FE2FF",
    "100": "#87DBFF",
    "200": "#33c4fd",
    "300": "#008ECB",
  },
  purple: {
    DEFAULT: "#CEABFF",
    "10": "#FAF7FF",
    "20": "#F5EEFF",
    "40": "#EBDDFF",
    "60": "#E2CDFF",
    "80": "#D8BCFF",
    "100": "#CEABFF",
    "200": "#A869FF",
    "400": "#7A1BFF",
  },
  pink: {
    DEFAULT: "#FF9B96",
    "20": "#FFE6DE",
    "40": "#FFCCBD",
    "60": "#FEB39D",
    "80": "#FFAFAB",
    "100": "#FF9B96",
    "200": "#EC352B",
  },
  yellow: {
    DEFAULT: "#FFD447",
    "60": "#FFE591",
    "80": "#FFDD6C",
    "100": "#FFD447",
    "200": "#C99C00",
    "300": "#8F6F00",
  },
};

export default {
  darkMode: ["class"],
  content: ["./components/**/*.{ts,tsx}"],
  theme: {
    container: {
      center: true,
      padding: "2rem",
      screens: {
        "2xl": "1400px",
      },
    },
    colors: {
      ...winclapColors,
      inherit: colors.inherit,
      current: colors.current,
      transparent: colors.transparent,
      gray: colors.gray,
      black: colors.black,
      white: colors.white,
      red: colors.red,
      alert: colors.yellow[200],
      success: winclapColors.green[200],
      error: winclapColors.pink[200],
      primary: winclapColors.purple,
      secondary: winclapColors.green,
    },
    fontFamily: {
      sans: ["Inter", "sans-serif"],
      judson: ["Judson", "serif"],
    },
    extend: {
      boxShadow: {
        lg: "0px 0px 8px rgba(0, 0, 0, 0.1)",
      },
      keyframes: {
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
        blink: {
          "0%": { opacity: "1" },
          "50%": { opacity: "0" },
          "100%": { opacity: "1" },
        },
        ...loaderKeyframes,
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
        "winclap-triangle-1": "winclapTriangle1 1s infinite",
        "winclap-triangle-2": "winclapTriangle2 1s infinite",
        "winclap-triangle-3": "winclapTriangle3 1s infinite",
        blink: "blink 1s step-start infinite",
      },
      transitionProperty: {
        width: "width",
      },
    },
  },
  plugins: [tailwindcssAnimate],
} satisfies Config;
