const triangle1 =
  "path('M248.81,310.17,157.45,419.34,66,310.17H248.81m18.86-8.81H47.13l108.06,129h4.52l108-129Z')";
const triangle2 =
  "path('M343.83,210.54,257.09,413.22,170.73,210.54h173.1m13.35-8.81H157.4l97.41,228.63h4.52l97.85-228.63Z')";
const triangle3 =
  "path('M445.37,110.92,357.18,407.08,269,110.92H445.37m11.81-8.81h-200l97.74,328.25h4.52l97.74-328.25Z')";
export const loaderKeyframes = {
  winclapTriangle1: {
    "0%": {
      fill: "transparent",
      transform: "translateX(30%)",
    },
    "30%": {
      fill: "currentColor",
      transform: "translateX(0%)",
    },
    "95%": {
      fill: "currentColor",
    },
    "100%": {
      fill: "transparent",
    },
  },
  winclapTriangle2: {
    "0%, 30%": {
      fill: "transparent",
      d: triangle1,
    },
    "31%": {
      fill: "currentColor",
      d: triangle1,
    },
    "60%, 95%": {
      fill: "currentColor",
      d: triangle2,
    },
    "100%": {
      fill: "transparent",
    },
  },
  winclapTriangle3: {
    "0%, 60%": {
      fill: "transparent",
      d: triangle2,
    },
    "61%": {
      fill: "currentColor",
      d: triangle2,
    },
    "95%": {
      fill: "currentColor",
      d: triangle3,
    },
    "100%": {
      fill: "transparent",
    },
  },
};
