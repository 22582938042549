export const localization = {
  organizationSwitcher: {
    action__createOrganization: "Create company",
    action__manageOrganization: "Manage company",
  },
  organizationProfile: {
    profilePage: {
      title: "Company Profile",
      dangerSection: {
        leaveOrganization: {
          title: "Leave company",
          messageLine1:
            "Are you sure you want to leave this company? You will lose access to this company and its applications.",
          messageLine2: "This action is permanent and irreversible.",
          successMessage: "You have left the company.",
        },
      },
      domainSection: {
        subtitle:
          "Allow users to join the company automatically or request to join based on a verified email domain.",
      },
    },
    createDomainPage: {
      subtitle:
        "Add the domain to verify. Users with email addresses at this domain can join the company automatically or request to join.",
    },
    invitePage: {
      subtitle: "Invite new members to this company",
    },
    membersPage: {
      requestsTab: {
        autoSuggestions: {
          headerSubtitle:
            "Users who sign up with a matching email domain, will be able to see a suggestion to request to join your company.",
        },
        requests: {
          headerSubtitle:
            "Browse and manage users who requested to join the company.",
        },
      },
      invitationsTab: {
        autoInvitations: {
          headerSubtitle: `Invite users by connecting an email domain with your organization. 
              Anyone who signs up with a matching email domain will be able to join the organization anytime.`,
        },
      },
    },
  },
};
