"use client";

import { Clerk } from "@clerk/clerk-js";
import { ClerkProvider, SignedIn, SignedOut } from "@clerk/nextjs";
import { LogIn } from "~/components/auth/sign-in";
import { localization } from "~/config/clerk";
import { OrganizationGuard } from "./org-guard";
import { colors } from "@winclap-platform/ui/utils/tailwind";

export const clerk = new Clerk(
  process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY as string,
);

export const AuthProvider = ({
  children,
  ...props
}: React.ComponentProps<typeof ClerkProvider>) => {
  return (
    <ClerkProvider
      localization={localization}
      {...props}
      Clerk={clerk}
      appearance={{
        variables: {
          fontFamily: "Inter",
          colorPrimary: colors.primary[200],
          borderRadius: "4px",
        },
      }}
    >
      <SignedOut>
        <LogIn />
      </SignedOut>
      <SignedIn>
        <OrganizationGuard>{children}</OrganizationGuard>
      </SignedIn>
    </ClerkProvider>
  );
};
