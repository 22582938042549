"use client";

import { Slot } from "@radix-ui/react-slot";
import { cn } from "@winclap-platform/ui/utils/tailwind";
import { cva, VariantProps } from "class-variance-authority";
import { Loader2 } from "lucide-react";
import * as React from "react";

const buttonVariants = cva(
  "group inline-flex gap-2 items-center whitespace-nowrap justify-center rounded-md text-sm font-normal ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-200 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50 dark:ring-offset-gray-950 dark:focus-visible:ring-gray-300",
  {
    variants: {
      variant: {
        primary:
          "bg-primary-200 text-gray-50 hover:bg-primary-400 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 disabled:bg-gray-200 disabled:opacity-100 disabled:text-gray-400 active:bg-primary-200",
        destructive:
          "bg-error text-gray-50 hover:bg-error/90 dark:bg-red-900 dark:text-gray-50 dark:hover:bg-red-900/90 active:bg-error",
        outline:
          "border border-gray-200 bg-white hover:bg-gray-100 hover:text-gray-900 dark:border-gray-800 dark:bg-gray-950 dark:hover:bg-gray-800 dark:hover:text-gray-50 [&>svg]:disabled:text-gray-400",
        icon: "bg-transparent border text-primary-200 hover:text-primary-400 border-primary-200  hover:border-primary-400 hover:bg-primary-20 disabled:text-gray-400 disabled:border-gray-400 dark:border-gray-800 dark:bg-gray-950 dark:hover:bg-gray-800 dark:hover:text-gray-50 active:bg-transparent",
        secondary:
          "bg-gray-100 text-gray-900 hover:bg-gray-100/80 dark:bg-gray-800 dark:text-gray-50 dark:hover:bg-gray-800/80",
        ghost:
          "text-primary-200 hover:text-primary-400 disabled:bg-gray-100 hover:bg-primary-20 dark:hover:bg-gray-800 dark:hover:text-gray-50 disabled:text-gray-400 active:bg-primary-40",
        table:
          "text-primary-200 hover:text-primary-400 hover:bg-white active:bg-transparent",
        link: "font-semibold text-primary-200 hover:text-primary-400 active:text-primary-200 underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 px-4 py-2",
        xs: "size-4",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "size-9 p-2",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "default",
    },
  },
);

type ButtonVariants = VariantProps<typeof buttonVariants>;

type LoaderVariants = {
  variant: Record<NonNullable<ButtonVariants["variant"]>, string>;
};

const loaderVariants = cva<LoaderVariants>("absolute !visible m-auto animate-spin", {
  variants: {
    variant: {
      primary: "text-gray-50",
      destructive: "text-gray-50",
      outline: "text-gray-400",
      icon: "text-primary-200",
      secondary: "text-gray-400",
      ghost: "text-primary-200",
      table: "text-primary-200",
      link: "text-primary-200",
    },
  },
  defaultVariants: {
    variant: "primary",
  },
});

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    ButtonVariants {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      loading,
      asChild,
      children,
      onClick,
      type = "button",
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size }),
          className,
          loading
            ? "pointer-event-none cursor-default text-transparent [&>svg]:invisible"
            : "",
        )}
        onClick={!loading ? onClick : undefined}
        ref={ref}
        type={type}
        {...props}
      >
        {children}
        {loading && (
          <Loader2 className={cn(loaderVariants({ variant }), "size-6")} />
        )}
      </Comp>
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
